<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t('message.update_branch') }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)">{{ $t('message.save_and_close') }}</el-button>
              <el-button @click="close()">{{  $t('message.close') }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row" v-loading="loadingData">
      <div class="col-12 mt-2">
        <div class="modal-body">
          <el-form ref="form" :model="form" :rules="rules" class="aticler_m" size="small">
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item :label="$t('message.branch')" prop="name">
                  <el-input v-model="form.name" autocomplete="off"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('message.code_id')">
                  <el-input v-model="form.code" :disabled="true"/>
                </el-form-item>
              </el-col>
              <el-col :sm="12">
                <el-form-item :label="$t('message.owner_branch')">
                  <el-select
                    filterable
                    :placeholder="$t('message.owner_branch')"
                    size="mini"
                    v-model="form.owner_branch_id"
                  >
                    <el-option
                      v-for="item in owner_branches"
                      :key="item.name + item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex';
import form from '@/utils/mixins/form';
import drawerChild from '@/utils/mixins/drawer-child';
export default {
  mixins:[form, drawerChild],
  props: ['selected'],
  data(){
    return {
      loadingData: false
    }
  },
  computed: {
    ...mapGetters({
      rules: 'branches/rules',
      model: 'branches/model',
      columns: 'branches/columns',
      owner_branches: "ownerBranches/list",
    })
  },
  methods:{
    ...mapActions({
      save: 'branches/update',
      editModel: 'branches/show',
      empty: 'branches/empty',
      loadOwnerBranches: 'ownerBranches/index',
    }),
    afterOpened(){
      this.loadOwnerBranches();
      if (this.selected && !this.loadingData) {
        this.loadingData = true;
        this.editModel(this.selected.id)
          .then(async (res) => {
            this.loadingData = false;
            this.form = JSON.parse( JSON.stringify(this.model) );
          })
          .catch(err => {
            this.loadingData = false;
            this.$notify({
              title: "Ошибка",
              type: "error",
              offset: 130,
              message: err.error.message,
            })
          });
      }

    },
    afterClosed(){
      this.empty()
    },
    loadModel(){
      this.form = JSON.parse( JSON.stringify( this.model ));
    },
    submit(close = true){
      this.$refs['form'].validate((valid) => {
        if (valid){
          this.loadingButton = true;
          this.save(this.form)
            .then(res => {
              this.loadingButton = false;
              this.$notify({
                title: 'Успешно',
                type: "success",
                offset: 130,
                message: res.message
              });
              this.parent().listChanged()
              if (close == true) {
                this.close();
              }
            })
            .catch(err => {
              this.loadingButton = false;
              this.$notify({
                title: 'Ошибка',
                type: "error",
                offset: 130,
                message: err.error.message
              });
            });
        }
      });
    }
  }
}

</script>
